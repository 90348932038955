import React from "react"
import { Row, Col, Container, Form, Button } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

import ReCAPTCHA from "react-google-recaptcha";

// import indexImage from "../images/monitoring-aerobarrier.jpg"

// import serviceArea from "../images/service-area.jpg"

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const GetAQuote = (props) => {
  const recaptchaRef = React.useRef();

  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    streetAddress: '',
    city: '',
    state: '',
    squareFeet: '',
    projectType: 'newConstruction',
    currentStage: '',
    details: '',
  })

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert("Please verify you're not a robot before submitting.");
      return;
    }
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "get a quote", ...formData })
    })
      .then(() => {
         window.location.href = "/get-a-quote-thank-you/";
      })
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <section className="page-section">
        <Container className="singleCol">

        <h1 className="text-center">Get a Quote</h1>

<p>
  Please complete the below form and we will generate a Preliminary Quote to be emailed to you.  Providing your phone number, though not required, is useful in case we need to contact you for additional project information.  
</p>
<p>
  <em>
    Note that the AeroBarrier process is primarily designed for New Construction and Complete/Gut Remodels.  Installations for both finished/unoccupied and furnished/occupied homes requires a significant amount of additional preparation, and the cost for that preparation cannot be included on a Preliminary Quote as cost determination will require a walk-through by us to evaluate the conditions. 
  </em>
</p>
<p>
  <em>Generally you can estimate for finished/furnished/occupied homes an added cost of $2.00-$3.00 per square foot for additional preparation.</em>
</p>

              <Form 
                data-netlify="true"
                name="get a quote"
                method="post"
                onSubmit={onSubmitWithReCAPTCHA}

                className="one-col-form mx-auto mb-3"
              >
                <input type="hidden" name="form-name" value="get a quote" />
                

                    <p className="lead"><strong>Contact Info</strong></p>

                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control 
                                      value={formData.firstName} onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                                        type="text" name="firstName"
                                        required
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control 
                                      value={formData.lastName} onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                                       type="text" name="lastName"
                                       required
                                     />
                            </Form.Group>
                            </Col>
                        </Row>                            
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                              value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}
                                required
                                type="email" 
                                name="email" 
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone <small>(Optional)</small></Form.Label>
                            <Form.Control 
                              value={formData.phone} onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                type="text" 
                                name="phone" 
                            />
                        </Form.Group>

                        <p className="lead"><strong>Project Information</strong></p>

                        <Row>
                          <Col lg={12}>
                            <Form.Group>
                              <Form.Label>Street Address <small>(Optional)</small></Form.Label>
                              <Form.Control 
                                value={formData.streetAddress} onChange={(e) => setFormData({...formData, streetAddress: e.target.value})}
                                type="text" 
                                name="streetAddress" 
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={7}>
                            <Form.Group>
                              <Form.Label>City</Form.Label>
                              <Form.Control 
                                value={formData.city} onChange={(e) => setFormData({...formData, city: e.target.value})}
                                required
                                type="text" 
                                name="city" 
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={5}>
                            <Form.Group>
                              <Form.Label>State</Form.Label>
                              <Form.Control 
                                value={formData.state} onChange={(e) => setFormData({...formData, state: e.target.value})}
                                required
                                type="text" 
                                name="state" 
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group>
                          <Form.Label>Square Feet (of Living/Conditioned Space)</Form.Label>
                          <Form.Control 
                            value={formData.squareFeet} onChange={(e) => setFormData({...formData, squareFeet: e.target.value})}
                            required
                            type="text" 
                            name="squareFeet" 
                          />
                        </Form.Group>

                        <Form.Group className="mt-2">
                          <Form.Label>Project Type</Form.Label>
                          <select className="form-select" aria-label="Default select example" required name="projectType" value={formData.projectType} onChange={(e) => setFormData({...formData, projectType: e.target.value})}>
                            <option value="newConstruction" selected>New Construction</option>
                            <option value="completeGutRemoval">Complete/Gut Remodel</option>
                            <option value="partialRemodel">Partial Remodel</option>
                            <option value="finishedAndUnoccupied">Finished & Unoccupied</option>
                            <option value="finishedAndOccupied">Furnished & Occupied</option>
                          </select>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Current Stage of Construction/Remodel</Form.Label>
                            <Form.Control 
                                value={formData.currentStage} onChange={(e) => setFormData({...formData, currentStage: e.target.value})}
                                as="textarea" 
                                name="currentStage"
                                rows="2"
                             />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label>Other details you would like to provide to us</Form.Label>
                            <Form.Control 
                              value={formData.details} onChange={(e) => setFormData({...formData, details: e.target.value})}
                                as="textarea" 
                                name="details"
                                rows="4"
                             />
                        </Form.Group>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                />
                <Button className="mt-2" type="submit">Get a Quote</Button>
              </Form>
            </Container>
            </section>

    </Layout>
  );
};

export default GetAQuote;

export const Head = () => <Seo title="Get a Quote" />

